
import { defineComponent } from "vue";
import BaseSearch from "@/components/BaseSearch.vue";
import List from "@/components/announcement/List.vue";
import ListItem from "@/components/announcement/ListItem.vue";
import { ListAnnouncement } from "@/components/announcement/index";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "AnnouncementSearch",
  components: {
    BaseSearch,
    List,
    ListItem,
    InfiniteLoading,
    LoadingIcon,
  },
  data() {
    return {
      category: "",
      keyword: "",
      announcementList: [] as ListAnnouncement,
      infiniteId: +new Date(),
      page: 1,
      isSearched: false,
    };
  },
  watch: {
    keyword(newValue) {
      if (newValue) this.isSearched = false;
    },
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.announcementList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    load() {
      return this.$axios
        .get("/announcement/external", {
          params: {
            page: this.page,
            keyword: this.keyword,
            role: this.category,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.announcementList.splice(0, this.announcementList.length);
              this.announcementList.push(...res);
            } else {
              this.announcementList.push(...res);
            }
          }
          return result;
        });
    },
    goTarget(link: string) {
      if (!link) return;
      this.$flutter.callHandler("launchUrl", { url: link });
    },
    search() {
      if (this.keyword) {
        this.$pushGAEvent("search", {
          type: "announcement",
          announcement_category: this.category == "배우" ? "actor" : "staff",
          search_keyword: this.keyword,
        });
      }

      this.isSearched = true;
      this.announcementList.splice(0, this.announcementList.length);
      this.page = 1;
      this.infiniteId++;
    },
  },
  mounted() {
    if (this.$route.query) {
      this.category = (this.$route.query as { category: string }).category;
    }
  },
});
